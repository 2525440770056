<template>
  <div class="home">
    <div class="columns is-multiline is-mobile">
      <div class="column is-4-tablet is-6-mobile is-3-desktop">
        <div class="column is-4-tablet is-6-mobile is-6-desktop">
          <home-cards-item
              prefix="tools"
              :data="{name: 'equipment-communications', icon: 'desktop', color:'is-primary',route:{name:'equipment-communications'},exact: false,onlyLapi: false}"
          ></home-cards-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeCardsItem from "@/components/dashboard/HomeCardsItem";
export default {
  name: "Menu",
  components: {HomeCardsItem},
  computed: {
    cardLinks(){
      return this.$store.state.tools.links || []
    }
  },
  mounted() {
      console.log(this.$store.state.menu.menuApps);
      if(this.$store.state.menu.menuApps){
          this.$store.commit('toggleMenuApps');
      }
  }
}
</script>

<style scoped>

</style>