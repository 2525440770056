<template>
    <router-link
    :to="data">
        <div class="square-icon-box"
        :class="data.color">
            <div class="icon-box-wrapper">
                <b-icon class="icon-box"
                :icon="data.icon">
                </b-icon>
            </div>
            <div class="box-title">
                {{ $t(`${prefix}${prefix? '.' : ''}${data.name}.title`) }}
            </div>
            <div class="box-text">
                {{ $t(`${prefix}${prefix? '.' : ''}${data.name}.body`) }}
            </div>
        </div>
    </router-link>

</template>

<script>
    export default {
        name: "HomeCardsItem",
        props: {
            data: Object,
            prefix: {
              type: String,
              default: ''
            }
        }
    }
</script>

<style scoped>

</style>
